import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class NavigateService {

    constructor(private router: Router) {

    }

    public navigate(command: any[], extras?: NavigationExtras): void {
        this.router.navigate(command, extras);
    }

    public navigateToLogin(): void {
        this.router.navigateByUrl('/sessions/signin');
    }
}