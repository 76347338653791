import { Injectable } from '@angular/core';
import { sessionDTO } from 'app/shared/models/sessionDTO';

const SESSION = 'SESSION';

@Injectable({
  providedIn: 'root'
})

export class LocalStorageService {


  constructor() { }

  setSession(session: sessionDTO): void {
    localStorage.setItem(SESSION, JSON.stringify(session));
  }

  getSession(): sessionDTO {
    return  JSON.parse(localStorage.getItem(SESSION)) as sessionDTO;
  }

  isAdmin(): boolean{
    if(this.getSession().profile.rol == "admin"){
      return true;
    }
    return false;
  }

  isLogged(): boolean {
    let session = this.getSession();
    return session != null && session.access_token != null;
  }

  clearAll(): void {
    localStorage.clear();
  }
  
}
