import {Pipe, PipeTransform} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Observable} from 'rxjs/Observable';
import "rxjs-compat/add/operator/map";
import {catchError, map} from "rxjs/operators";
import {of} from "rxjs";

@Pipe({
    name: 'SanitizeImageUrl'
})
export class SanitizeImageUrlPipe implements PipeTransform {

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
    }

    transform(url, fallback: string): Observable<SafeUrl> {
        return this.http
            .get(url, {responseType: 'blob'}).pipe(
                map(val => {
                    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val));
                }),
                catchError(() => {
                    return of(this.sanitizer.bypassSecurityTrustUrl(fallback));
                }));
    }

}