import { ObserverService } from 'app/shared/services/serviceSlideMenu/observer.service';
import {Component, OnInit, EventEmitter, Input, Output, Renderer2, ChangeDetectorRef} from '@angular/core';
import {ThemeService} from '../../services/theme.service';
import {LayoutService} from '../../services/layout.service';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from 'app/shared/services/app-ts/local-storage.service';
import {NavigateService} from 'app/shared/services/app-ts/navigate.service';
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-header-side',
    templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
    @Input() notificPanel;
    public availableLangs = [{
        name: 'EN',
        code: 'en',
        flag: 'flag-icon-us'
    }, {
        name: 'ES',
        code: 'es',
        flag: 'flag-icon-es'
    }]
    currentLang = this.availableLangs[0];

    public egretThemes;
    public layoutConf: any;
    private session = this.localStorageService.getSession();
    public profilePicture = null;

    constructor(
        private themeService: ThemeService,
        private layout: LayoutService,
        public translate: TranslateService,
        private renderer: Renderer2,
        private localStorageService: LocalStorageService,
        private navigateService: NavigateService,
        private observerService: ObserverService,
        private detectChange: ChangeDetectorRef
    ) {
        //Esta linea da error 404.
        this.profilePicture = environment.apiURL + '/api/v1/users/' + this.session.profile.id + '/picture';
        this.observerService.getSessionObvservable().subscribe(
            resp => {
                //Al cambiar la foto, la URL no se actualiza, por eso se fuerza el refresh con el queryParam para no hacer un reload del sitio completo.
                this.profilePicture = environment.apiURL + '/api/v1/users/' + this.session.profile.id + '/picture?' + new Date().getTime();
                this.detectChange.detectChanges();
            }
        );

    }

    ngOnInit() {
        this.egretThemes = this.themeService.egretThemes;
        this.layoutConf = this.layout.layoutConf;
        this.translate.use(this.currentLang.code);
    }

    setLang(lng) {
        this.currentLang = lng;
        this.translate.use(lng.code);
    }

    changeTheme(theme) {
        // this.themeService.changeTheme(theme);
    }

    toggleNotific() {
        this.notificPanel.toggle();
    }

    toggleSidenav() {
        if (this.layoutConf.sidebarStyle === 'closed') {
            return this.layout.publishLayoutChange({
                sidebarStyle: 'full'
            })
        }
        this.layout.publishLayoutChange({
            sidebarStyle: 'closed'
        })
    }

    toggleCollapse() {
        // compact --> full
        if (this.layoutConf.sidebarStyle === 'compact') {
            return this.layout.publishLayoutChange({
                sidebarStyle: 'full',
                sidebarCompactToggle: false
            }, {transitionClass: true})
        }

        // * --> compact
        this.layout.publishLayoutChange({
            sidebarStyle: 'compact',
            sidebarCompactToggle: true
        }, {transitionClass: true})

    }

    logout() {
        this.localStorageService.clearAll();
        this.navigateService.navigateToLogin();
    }
}