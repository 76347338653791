import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";
import { LocalStorageService } from "app/shared/services/app-ts/local-storage.service";
import { NavigateService } from "app/shared/services/app-ts/navigate.service";
import { UserDTO } from "app/shared/models/userDTO";
import {environment} from "../../../../environments/environment";
import { ObserverService } from "app/shared/services/serviceSlideMenu/observer.service";

@Component({
  selector: "app-sidebar-side",
  templateUrl: "./sidebar-side.component.html",
  styleUrls: ['./sidebar-side.component.css']
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;
  public user: UserDTO;
  public profilePicture = null;
  private session = this.localStorageService.getSession();

  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    private layout: LayoutService,
    private localStorageService: LocalStorageService,
    private navigateService: NavigateService,
    private _observer: ObserverService,
    private _changeDetector: ChangeDetectorRef
  ) {
    //Esta linea da error 404.
    this.profilePicture = environment.apiURL + '/api/v1/users/' + this.session.profile.id + '/picture';
    this._observer.getSessionObvservable().subscribe(
      resp => {
          //Al cambiar la foto, la URL no se actualiza, por eso se fuerza el refresh con el queryParam para no hacer un reload del sitio completo.
          this.profilePicture = environment.apiURL + '/api/v1/users/' + this.session.profile.id + '/picture?' + new Date().getTime();
          this._changeDetector.detectChanges();
      }
  );
  }

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.getMenuItems(this.localStorageService.getSession().profile.rol).subscribe(menuItem => {
      this.menuItems = menuItem;
      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        item => item.type === "icon"
      ).length;
    });
    this.layoutConf = this.layout.layoutConf;

    this.user = this.localStorageService.getSession().profile;
    this._observer.getSessionObvservable().subscribe(
      session => {
        this.user = session.profile;
        this._changeDetector.detectChanges();
      }
    )
  }
  ngAfterViewInit() {
  }
  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }
  toggleCollapse() {
    if (
      this.layoutConf.sidebarCompactToggle
    ) {
        this.layout.publishLayoutChange({
        sidebarCompactToggle: false
      });
    } else {
        this.layout.publishLayoutChange({
            // sidebarStyle: "compact",
            sidebarCompactToggle: true
          });
    }
  }

  logout(){
    this.localStorageService.clearAll();
    this.navigateService.navigateToLogin();
  }
}
