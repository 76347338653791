import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  constructor() {}

  private adminMenu: IMenuItem[] = [
    {
      type: "separator",
    },
    {
      name: "PROJECTS",
      type: "link",
      tooltip: "Projects",
      icon: "timeline",
      state: "projects",
    },
    {
      name: "REPORTS",
      type: "link",
      tooltip: "Reports",
      icon: "assignment",
      state: "reports",
    },
    {
      name: "HOLIDAYS",
      type: "link",
      tooltip: "Holidays",
      icon: "event",
      state: "holidays",
    },
    {
      name: "USERS",
      type: "link",
      tooltip: "Users",
      icon: "people",
      state: "users",
    },
    /*{
      name: "SETTINGS",
      type: "link",
      tooltip: "Settings",
      icon: "settings",
      state: "settings"
    },*/
    {
      name: "CLIENTS",
      type: "link",
      tooltip: "Clients",
      icon: "people",
      state: "clients",
    },
    {
      name: "HOURS_REPORT",
      type: "link",
      tooltip: "Hours Report",
      icon: "timer",
      state: "hours-report",
    },
  ];

  private normalMenu: IMenuItem[] = [
    {
      type: "separator",
    },
    {
      name: "PROJECTS",
      type: "link",
      tooltip: "Projects",
      icon: "timeline",
      state: "projects",
    },
    {
      name: "REPORTS",
      type: "link",
      tooltip: "Reports",
      icon: "assignment",
      state: "reports",
    },
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = "Frequently Accessed";

  public getMenuItems(type: string): Observable<IMenuItem[]> {
    if (type == "admin")
      return new BehaviorSubject<IMenuItem[]>(this.adminMenu).asObservable();
    else
      return new BehaviorSubject<IMenuItem[]>(this.normalMenu).asObservable();
  }

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.

  publishNavigationChange(menuType: string) {
    //switch (menuType) {
    //   case "separator-menu":
    //    this.menuItems.next(this.separatorMenu);
    //     break;
    //   case "icon-menu":
    //     this.menuItems.next(this.iconMenu);
    //     break;
    //   default:
    //     this.menuItems.next(this.plainMenu);
    // }
  }
}
