import { Injectable } from '@angular/core';
import { sessionDTO } from 'app/shared/models/sessionDTO';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObserverService {

  private sessionUser: Subject<sessionDTO>;

  constructor() { 
    this.sessionUser = new Subject();
  }

  public setSession(session: sessionDTO){
    this.sessionUser.next(session);
  }

  public getSessionObvservable(): Observable<sessionDTO>{
    return this.sessionUser.asObservable();
  }
}
