import { Routes } from '@angular/router';
import { MainLayoutComponent } from './shared/components/layouts/main-layout/main-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/services/auth/auth.guard';
import { RoleGuard } from './shared/services/auth/role.guard';
import { FullscreenOverlayContainer } from '@angular/cdk/overlay';
import {BuildsModule} from "./views/builds/builds.module";
import {BuildsRoutes} from "./views/builds/builds.routing";
import { PageWorkComponent } from './views/in-work/page-work/page-work.component';

export const rootRouterConfig: Routes = [

  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'projects',
        pathMatch: 'full'
      },
      {
        path: 'projects',
        //canActivate: [RoleGuard],
        loadChildren: () => import('./views/projects/projects.module').then(m => m.ProjectsModule)
      },
      {
        path: 'users',
         canActivate: [RoleGuard],
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'reports',
       loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'hours-report',
       loadChildren: () => import('./views/hours-report/hours-report.module').then(m => m.HoursReportModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'clients',
         canActivate: [RoleGuard],
        loadChildren: () => import('./views/clients/clients.module').then(m => m.ClientsModule)
      },
      {
        path: 'holidays',
        canActivate: [RoleGuard],
        loadChildren: () => import('./views/calendar/calendar.module').then(m => m.CalendarModule)
      },
      {
        path: 'in-work',
         canActivate: [RoleGuard],
        loadChildren: () => import('./views/in-work/in-work.module').then(m => m.InWorkModule)
      }
    ]
  },

  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'builds',
        loadChildren: () => import('./views/builds/builds.module').then(m => m.BuildsModule),
        data: { title: 'Builds' }
      },
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session' }
      }
    ]
  },

  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];
