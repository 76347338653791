import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LocalStorageService } from '../app-ts/local-storage.service';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.localStorageService.isAdmin()) {
      return true
    }

    this.router.navigate(['/reports']);

    return false;
  }
}