import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';
import { Injectable } from '@angular/core';
import { AppWarningComponent } from './app-warning.component';

interface ConfirmData {
  title?: string;
  messages?: string[];
}

@Injectable()
export class AppWarningService {

  constructor(private dialog: MatDialog) { }

  public confirm(data: ConfirmData = {}): Observable<boolean> {
    data.title = data.title || 'Warning';
    data.messages = data.messages || [];
    let dialogRef: MatDialogRef<AppWarningComponent>;
    dialogRef = this.dialog.open(AppWarningComponent, {
      width: '380px',
      disableClose: true,
      data: {title: data.title, messages: data.messages}
    });
    return dialogRef.afterClosed();
  }
}
