import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';
import {LocalStorageService} from './shared/services/app-ts/local-storage.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private storage: LocalStorageService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const session = this.storage.getSession();
        if (session === null || request.headers.get('authorization')) {
            return next.handle(request);
        }

        const jwtToken = session.access_token;
        const requestToHandle = request.clone({
            headers: request.headers.set('authorization', `Basic ${jwtToken}`)
        });
        return next.handle(requestToHandle);
    }
}
